import React from 'react'

import { BoxGroup, Paragraph } from '@te-digi/styleguide'

import { BoxJobExample } from '../../examples/react/BoxJobExample'
import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { LinkMock } from '../../components/LinkMock'
import { Section } from '../../components/Section'
import IconHTMLExample from '../../examples/html/IconHTMLExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="BoxGroup"
    components={[{ component: BoxGroup, restElement: true }]}
    status={[{ type: 'accessible', version: '14.2.0' }]}
  >
    <Section>
      <Paragraph>
        <Code>Box</Code>-komponentilla luotuja laatikoita voi sulauttaa
        toisiinsa ympäröimällä laatikot <Code>BoxGroup</Code>-komponentilla.
      </Paragraph>
      <Playground example={BoxJobExample} />
      <Playground format="html">
        <LinkMock className="box-group">
          <div className="box bg-light p-sm">
            <h3 className="h5 text-center text-primary mb-0">Myyjä</h3>
          </div>
          <div className="box p-sm mb-0">
            <p className="lead mb-0">TE-toimisto</p>
            <div className="flex mt-sm">
              <div className="mr-xs">
                <IconHTMLExample
                  name="LocationIcon"
                  size="lg"
                />
              </div>
              <div>Tampere</div>
            </div>
            <p className="mb-0">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Quibusdam reprehenderit nihil corrupti! Impedit, quas! Sequi, quia
              debitis exercitationem cupiditate dolorem voluptates accusamus
              nisi.
            </p>
            <div className="mt-sm">
              <ul className="badges mb-0">
                <li className="badges-item">
                  <span className="badge">Aipisicing</span>
                </li>
                <li className="badges-item">
                  <span className="badge">Consectetur</span>
                </li>
              </ul>
            </div>
          </div>
        </LinkMock>
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Komponentille ei ole erityisiä saavutettavuushuomioita.
      </Paragraph>
    </Section>
  </Content>
)

export default Page
